<template>
    <div>
        <div class="header-background">
            <div class="qr-code-box">
                <div class="system-type">
                    <div class="android" @click="gotoDownload('android')">
                        <img
                            v-if="systemType === 'android'"
                            src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625807621940.png"
                            alt=""
                        />
                        <img
                            v-else
                            src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626252998330.png"
                            alt=""
                        />
                    </div>
                    <div class="iphone" @click="gotoDownload('iphone')">
                        <img
                            v-if="systemType === 'android'"
                            src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625807659603.png"
                            alt=""
                        />
                        <img
                            v-else
                            src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626253059619.png"
                            alt=""
                        />
                    </div>
                </div>
                <div class="qr-code-img-box">
                    <div class="qr-code">
                        <!-- <img v-show="systemType==='android'" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251728619.png" alt="">
            <img v-show="systemType==='iphone'" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251938569.png" alt=""> -->
                    </div>
                    <div class="qr-code-text">扫一扫，立即下载</div>
                </div>
            </div>
        </div>
        <div class="other-platforms-box">
            <div class="other-platforms">
                <div class="other-platforms-top">
                    <img
                        src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251680546.png"
                        alt=""
                    />
                </div>
                <div class="other-platforms-bottom">微信公众号</div>
            </div>
            <div class="other-platforms">
                <div class="other-platforms-top">
                    <img
                        :src="qrcodeImg"
                        alt=""
                    />
                </div>
                <div class="other-platforms-bottom">微信小程序</div>
            </div>
            <div class="other-platforms">
                <div class="other-platforms-top">
                    <img
                        src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251643058.png"
                        alt=""
                    />
                </div>
                <div class="other-platforms-bottom">墨鱼旅行微博</div>
            </div>
            <div class="other-platforms">
                <div class="other-platforms-top">
                    <img
                        src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251621305.png"
                        alt=""
                    />
                </div>
                <div class="other-platforms-bottom">墨鱼旅行知乎</div>
            </div>
            <div class="other-platforms">
                <div class="other-platforms-top">
                    <img
                        src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251568049.png"
                        alt=""
                    />
                </div>
                <div class="other-platforms-bottom">墨鱼旅行小红书</div>
            </div>
        </div>
    </div>
</template>

<script>
import qrcodeImg from '@/assets/images/common/wechatMiniProgramQrcode.jpg'
export default {
    data() {
        return {
            systemType: "android",
            qrcodeImg: qrcodeImg
        };
    },
    methods: {
        gotoDownload(systemType) {
            console.log(systemType);
            this.systemType = systemType;
            window.location.href =
                "http://a.app.qq.com/o/simple.jsp?pkgname=com.moyu.moyu";
        },
    },
};
</script>

<style scoped lang="scss">
.header-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url(https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625646368673.png)
        no-repeat;
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;
    .qr-code-box {
        display: flex;
        justify-content: space-between;
        .system-type {
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img {
                height: 50px;
            }
        }
        .qr-code-img-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            .qr-code {
                width: 100px;
                height: 100px;
                background-image: url(https://oss.cuttlefish.vip/app/qrCode.png);
                background-size: 100% 100%;
            }

            .qr-code-text {
                font-size: 16px;
                font-family: PingFang-SC-Heavy, PingFang-SC;
                font-weight: 800;
                color: #ffffff;
            }
        }
    }
}
.other-platforms-box {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 10px;
    .other-platforms {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        .other-platforms-top {
            width: 60px;
            height: 60px;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .other-platforms-bottom {
            margin-top: 5px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
    }
}
</style>
